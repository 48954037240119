<template>
  <div class="admin-container">
    <template v-if="loaded">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn tile small @click="$router.push({ name: 'Buildings' })"
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="building.name"
            label="Název stavby *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3"
          ><v-select
            :items="customers"
            v-model="building.investor_id"
            label="Investor *"
            item-text="full_name"
            item-value="id"
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :items="buildingTypes"
            v-model="building.type"
            label="Typ stavby *"
          ></v-select
        ></v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="11" md="9" style="padding-right: 0">
              <v-text-field
                v-model="building.land_area"
                label="Plocha pozemku *"
              ></v-text-field>
            </v-col>
            <v-col
              align-self="center"
              style="padding-left: 2px; padding-right: 0"
            >
              <span>㎡</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="11" md="9" style="padding-right: 0">
              <v-text-field
                v-model="building.building_area"
                label="Plocha staby *"
              ></v-text-field>
            </v-col>
            <v-col
              align-self="center"
              style="padding-left: 2px; padding-right: 0"
            >
              <span>㎡</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3"
          ><v-text-field v-model="building.city" label="Město *"></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="building.address"
            label="Adresa *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="building.gps_coords"
            label="GPS stavby *"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="building.scope"
            label="Plánovaný rozsah dodávky *"
            auto-grow
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :items="config.months"
            item-text="infl"
            item-value="num"
            v-model="completion_date_month"
            label="Měsíc dokončení stavby *"
          ></v-select
        ></v-col>
        <v-col cols="12" md="3"
          ><v-select
            :items="config.years"
            v-model="completion_date_year"
            label="Rok dokončení stavby *"
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :items="projectManagers"
            v-model="building.project_manager_id"
            label="Projektový manager *"
            item-text="full_name"
            item-value="id"
          ></v-select
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="building.live_video_url"
            label="Url adresa na živý záznam"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Buildings' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="create">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import users from '../api/userService';
import authHandler from '../api/authHandler';
import userService from '../api/userService';
import buildingService from '../api/buildingService';
import permissionDenied from '../api/permissionDenied';

export default {
  data() {
    return {
      completion_date_month: null,
      completion_date_year: null,
      loaded: false,
      building: {},
      config: null,
      projectManagers: [],
      customers: [],
      buildingTypes: [],
    };
  },
  async created() {
    bus.$emit('processing');
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    await this.getUsers();
    await this.getBuildingTypes();
    await this.getConfig();
    this.loaded = true;
    bus.$emit('processing', false);
  },
  methods: {
    async getBuildingTypes() {
      try {
        const data = await buildingService.indexTypesConfig();
        this.buildingTypes = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getConfig() {
      try {
        const data = await buildingService.indexConfig();
        this.config = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsers() {
      try {
        const users = await userService.getUsers();
        this.projectManagers = users.filter((v) => v.role == 'PROJECT_MANAGER');
        this.customers = users.filter(
          (v) => v.role == 'COMPANY_CUSTOMER' || v.role == 'END_USER'
        );
      } catch (error) {
        console.error(error);
      }
    },
    async create() {
      try {
        if (!this.completion_date_month) {
          bus.$emit('snackbar', {
            text: 'Měsíc dokončení stavby je povinný',
            color: 'error',
          });
          return;
        }
        if (!this.completion_date_year) {
          bus.$emit('snackbar', {
            text: 'Rok dokončení stavby je povinný',
            color: 'error',
          });
          return;
        }
        bus.$emit('processing');
        let data = {
          ...this.building,
        };
        data.completion_date = `${this.completion_date_month}.${this.completion_date_year}`;

        const response = await buildingService.store(data);
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({ name: 'Buildings' });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
