import req from "./request";

export default {
  index: async (
    page = 1,
    limit = 25,
    orderBy = "id",
    descending = "desc",
    search = null,
    filter = null,
    filterFrom = null,
    filterTo = null
  ) => {
    try {
      let url = `buildings?page=${page}&limit=${limit}&orderBy=${orderBy}&descending=${
        descending ? "desc" : "asc"
      }`;
      if (search) url += `&search=${search}`;
      if (filter) url += `&filter=${filter}`;
      if (filterTo) url += `&filter_to=${filterTo}`;
      if (filterFrom) url += `&filter_from=${filterFrom}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  indexFilterConfig: async () => {
    try {
      const { data } = await req.get("buildings/filter-config", true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  indexConfig: async () => {
    try {
      const { data } = await req.get("buildings/config", true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  contacts: async () => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const { data } = await req.get(
        `buildings/contacts?building_id=${building.id || null}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  onlineLink: async () => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const { data } = await req.get(
        `buildings/${building.id}/online-link`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  indexTypesConfig: async () => {
    try {
      const { data } = await req.get("buildings/types-config", true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async (id) => {
    try {
      const { data } = await req.get(`buildings/${id}`, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  store: async (data) => {
    try {
      const response = await req.post(`buildings`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const response = await req.put(`buildings/${id}`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  destroy: async (id) => {
    try {
      const response = await req.delete(`buildings/${id}`, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
